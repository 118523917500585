import * as RToggle from '@radix-ui/react-toggle'
import {FC} from 'react'
import {StyledToggleRoot} from './style'

type ToggleProps = Omit<RToggle.ToggleProps, 'pressed' | 'onPressedChange' | 'value' | 'onChange'> & {
    value: boolean
    onChange: (toggled: boolean) => void
}

export const Toggle: FC<ToggleProps> = ({value, onChange, ...props}) => {
    return (
        <StyledToggleRoot {...props} pressed={value} onPressedChange={onChange}>
            <div className="circle" />
        </StyledToggleRoot>
    )
}
