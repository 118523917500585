import styled, {css} from 'styled-components'

export const StyledLabel = styled.label`
    ${({theme: {palette, truncateText, typography}}) => css`
        display: block;
        color: ${palette.neutral['700']};
        font-weight: 500;
        text-align: start;
        ${typography.textSm}
        ${truncateText}
    `}
`
